import React from "react"
import { css } from "@emotion/core"

import ipadStatusBar from "../../images/ipad-statusbar.jpg"

type IpadProps = {
  screenshot: any
}

const IpadProps = ({ screenshot }: IpadProps) => {
  return (
    <div
      css={css`
        max-width: 1024px;
        margin-bottom: 1.45rem;
        border: 10px solid #000;
        background: #343434;
        border-radius: calc(min(20px, 4vw));
        border-width: calc(min(10px, 1.6vw));
        position: relative;
        box-shadow: 0 8px 13px rgba(0, 0, 0, 0.3);
        overflow: hidden;
        font-size: 0;
        &:before {
          content: "";
          background: #ffffff;
          display: block;
          left: 0;
          right: 0;
          bottom: 0;
          height: calc(min(15px, 1vw));
          position: absolute;
          border-radius: 6px;
        }
        &:after {
          content: "";
          width: calc(min(173px, 20vw));
          height: calc(min(3px, 0.7vw));
          background: #343434;
          display: block;
          left: 50%;
          transform: translate(-50%, -50%);
          bottom: calc(min(3px, 0.5vw));
          position: absolute;
          border-radius: 6px;
        }
      `}
    >
      <img
        css={css`
          display: block;
          margin: 0;
        `}
        src={ipadStatusBar}
      />
      <img
        css={css`
          display: block;
          overflow: hidden;
          margin: 0;
        `}
        src={screenshot}
      />
    </div>
  )
}

export default IpadProps
