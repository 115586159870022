import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import screenshot from "../images/screenshot.png"
import screenshot2 from "../images/screenshot2.png"
import screenshot3 from "../images/screenshot3.png"
import Ipad from "../components/devices/Ipad"
import Iphone from "../components/devices/Iphone"
import SEO from "../components/seo"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 150,
    marginBottom: 30,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}))

const IndexPage = () => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="主页" />
      <style>{`html, body{background: #fff;color: #333;}`}</style>
      <section
        css={css`
          text-align: center;
        `}
      >
        <h1
          css={css`
            font-size: 40px;
            font-weight: 500;
            margin: 100px 0 0;
            color: #374769;
          `}
        >
          只关于自己的工作流
        </h1>
        <p
          css={css`
            font-size: 16px;
            color: #59647b;
            margin: 45px 0;
          `}
        >
          不止工作流，更是一种专注自己的管理方式
        </p>
        <div
          css={css`
            margin: 30px 0;
            svg {
              width: 20px;
              fill: #f50057;
            }
          `}
        >
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            href="/workboard"
            endIcon={
              <svg focusable="false" viewBox="0 0 24 24">
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
              </svg>
            }
            css={css`
              font-size: 18px !important;
              padding: 13px 20px !important;
              border-radius: 40px !important;
            `}
          >
            进入 Web 控制台
          </Button>
        </div>
        <div
          css={css`
            margin: 30px 0;
          `}
        >
          <p
            css={css`
              color: #868686;
              font-size: 13px;
            `}
          >
            我们想让你明白，你的时间不不仅仅被工作和生活琐事填满，还能有只关于自己的自留地。
          </p>
        </div>
      </section>
      <section
        css={css`
          text-align: center;
          position: relative;
        `}
      >
        <Ipad screenshot={screenshot} />
        <Iphone screenshot={screenshot2} />
        <p
          css={css`
            color: #868686;
          `}
        >
          提供网页端和手机 App
        </p>
      </section>
      <section
        css={css`
          text-align: center;

          svg {
            width: 40px;
            height: 40px;
            margin-bottom: 15px;
          }
          .MuiPaper-root:hover {
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
              0px 4px 5px 0px rgba(0, 0, 0, 0.14),
              0px 1px 10px 0px rgba(0, 0, 0, 0.12);
          }
        `}
      >
        <h3
          css={css`
            margin: 60px 0 20px;
            font-size: 32px;
            font-weight: 500;
          `}
        >
          实用功能
        </h3>
        <p
          css={css`
            margin: 20px 0 40px;
            font-size: 15px;
            color: #a2a2a2;
          `}
        >
          简单但是强大的功能，管理更方便
        </p>
        <Grid
          container
          spacing={3}
          css={css`
            p {
              margin: 0;
            }
          `}
        >
          <Grid item xs={6} sm={4} md={3}>
            <Paper elevation={0} className={classes.paper}>
              <svg
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="200"
              >
                <path
                  d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
                  fill="#F7C257"
                ></path>
                <path
                  d="M204.8 204.8m51.2 0l512 0q51.2 0 51.2 51.2l0 512q0 51.2-51.2 51.2l-512 0q-51.2 0-51.2-51.2l0-512q0-51.2 51.2-51.2Z"
                  fill="#FFAD1D"
                ></path>
                <path
                  d="M323.375595 481.284928m14.811382 20.880205l88.868293 125.281229q14.811382 20.880205-6.068822 35.691587l0 0q-20.880205 14.811382-35.691588-6.068823l-88.868293-125.281229q-14.811382-20.880205 6.068823-35.691587l0 0q20.880205-14.811382 35.691587 6.068823Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M371.042491 636.635886m20.880205-14.811382l334.083278-236.982116q20.880205-14.811382 35.691587 6.068823l0 0q14.811382 20.880205-6.068822 35.691587l-334.083279 236.982116q-20.880205 14.811382-35.691587-6.068823l0 0q-14.811382-20.880205 6.068823-35.691587Z"
                  fill="#FFFFFF"
                ></path>
              </svg>
              <p>管理自己的日常任务</p>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Paper elevation={0} className={classes.paper}>
              <svg
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="200"
              >
                <path
                  d="M511.996485 0c282.749416 0 512 229.247069 512 512 0 282.784567-229.247069 511.996485-512 511.996485S0 794.781052 0 512C0 229.247069 229.215433 0 511.996485 0z"
                  fill="#F4B459"
                ></path>
                <path
                  d="M384.031635 575.871701c-35.361252 0-64.065028 28.672141-64.065028 64.065028s28.672141 64.065028 64.065028 64.065029 64.065028-28.672141 64.065028-64.065029-28.675656-64.065028-64.065028-64.065028z m-18.914403-159.807552c-24.865358 0-44.992448 20.12709-44.992448 44.992449 0 24.830207 20.12709 44.992448 44.992448 44.992448 84.350295 0 152.991899 68.638089 152.991899 152.991899 0 24.830207 20.12709 44.992448 45.024083 44.992448 24.798572 0 44.992448-20.158726 44.992449-44.992448 0-133.986105-109.022326-242.976795-243.008431-242.976796z m0.864699-160.032514c-25.406673 0-46.015323 20.60865-46.015324 46.015324s20.60865 46.015323 46.015324 46.015323c170.911547 0 309.983908 139.040725 309.983907 309.952272 0 25.406673 20.577015 46.015323 46.015324 46.015324s46.015323-20.640286 46.015323-46.015324c-0.031635-221.665138-180.381052-401.982919-402.014554-401.982919z"
                  fill="#FFFFFF"
                ></path>
              </svg>
              <p>追踪自己喜欢的订阅</p>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Paper elevation={0} className={classes.paper}>
              <svg
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="200"
              >
                <path
                  d="M64 0m64 0l768 0q64 0 64 64l0 896q0 64-64 64l-768 0q-64 0-64-64l0-896q0-64 64-64Z"
                  fill="#0F67F5"
                ></path>
                <path
                  d="M192 524.608m38.4 0l563.2 0q38.4 0 38.4 38.4l0 0q0 38.4-38.4 38.4l-563.2 0q-38.4 0-38.4-38.4l0 0q0-38.4 38.4-38.4Z"
                  fill="#80D4FF"
                ></path>
                <path
                  d="M192 729.408m38.4 0l332.8 0q38.4 0 38.4 38.4l0 0q0 38.4-38.4 38.4l-332.8 0q-38.4 0-38.4-38.4l0 0q0-38.4 38.4-38.4Z"
                  fill="#80D4FF"
                ></path>
                <path
                  d="M192 128m32 0l384 0q32 0 32 32l0 192q0 32-32 32l-384 0q-32 0-32-32l0-192q0-32 32-32Z"
                  fill="#FFFFFF"
                ></path>
              </svg>
              <p>收藏自己刚看的文章</p>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Paper elevation={0} className={classes.paper}>
              <svg
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="200"
              >
                <path
                  d="M549.888 532.992c-6.656 0-13.824-2.048-19.968-5.632-17.92-11.264-23.04-34.304-12.288-52.224l282.112-452.096C811.008 5.12 834.56 0 852.48 10.752c17.92 11.264 23.04 34.304 12.288 52.224l-282.112 452.096c-7.68 11.776-19.968 17.92-32.768 17.92z"
                  fill="#6958FF"
                ></path>
                <path
                  d="M353.792 455.68H263.168c-20.992 0-37.888-16.896-37.888-37.888 0-20.992 16.896-37.888 37.888-37.888h90.624c20.992 0 37.888 16.896 37.888 37.888 0.512 20.992-16.896 37.888-37.888 37.888zM353.792 607.744H263.168c-20.992 0-37.888-16.896-37.888-37.888 0-20.992 16.896-37.888 37.888-37.888h90.624c20.992 0 37.888 16.896 37.888 37.888 0.512 20.992-16.896 37.888-37.888 37.888zM353.792 759.808H263.168c-20.992 0-37.888-16.896-37.888-37.888 0-20.992 16.896-37.888 37.888-37.888h90.624c20.992 0 37.888 16.896 37.888 37.888 0.512 20.992-16.896 37.888-37.888 37.888z"
                  fill="#212121"
                ></path>
                <path
                  d="M854.016 157.184h-48.128l-47.616 75.776h95.744c20.992 0 37.888 16.896 37.888 37.888v633.856c0 20.992-16.896 37.888-37.888 37.888H169.984c-20.992 0-37.888-16.896-37.888-37.888V271.36c0-20.992 16.896-37.888 37.888-37.888h498.688l47.616-75.776H169.984c-62.976 0-114.176 51.2-114.176 114.176v633.856c0 62.976 51.2 114.176 114.176 114.176h684.032c62.976 0 114.176-51.2 114.176-114.176V271.36c0-62.976-51.2-114.176-114.176-114.176z"
                  fill="#212121"
                ></path>
              </svg>
              <p>写写自己思考的文字</p>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Paper elevation={0} className={classes.paper}>
              <svg
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="200"
              >
                <path
                  d="M750.79 107.82H277.75c-97.97 0-177.39 79.42-177.39 177.39v627.84l191.35-95.67h459.08c97.97 0 177.39-79.42 177.39-177.39V285.21c0-97.97-79.42-177.39-177.39-177.39z m118.26 532.17c0 65.31-52.95 118.26-118.26 118.26H277.75l-118.26 59.13V285.21c0-65.32 52.95-118.26 118.26-118.26h473.04c65.31 0 118.26 52.94 118.26 118.26v354.78z"
                  fill="#445166"
                ></path>
                <path
                  d="M721.22 344.34h-413.9c-16.33 0-29.56 13.23-29.56 29.56s13.24 29.56 29.56 29.56h413.91c16.33 0 29.56-13.24 29.56-29.56 0-16.33-13.24-29.56-29.57-29.56zM543.84 521.73H307.32c-16.33 0-29.56 13.23-29.56 29.56s13.24 29.56 29.56 29.56h236.52c16.33 0 29.56-13.24 29.56-29.56 0-16.33-13.24-29.56-29.56-29.56z"
                  fill="#445166"
                ></path>
              </svg>
              <p>简单记录自己随时的小思想</p>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Paper elevation={0} className={classes.paper}>
              <svg
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="200"
              >
                <path
                  d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
                  fill="#DBE9F9"
                ></path>
                <path
                  d="M256 576a64 64 0 1 1 0-128 64 64 0 0 1 0 128z m256 0a64 64 0 1 1 0-128 64 64 0 0 1 0 128z m256 0a64 64 0 1 1 0-128 64 64 0 0 1 0 128z"
                  fill="#4A90E2"
                ></path>
              </svg>
              <p>更多功能上线中...</p>
            </Paper>
          </Grid>
        </Grid>
      </section>
      <section
        css={css`
          text-align: center;
          position: relative;
        `}
      >
        <Ipad screenshot={screenshot3} />
        <p
          css={css`
            color: #868686;
          `}
        >
          各个功能体验丝滑
        </p>
      </section>
      <section
        css={css`
          text-align: center;

          .MuiPaper-root {
            padding: 20px 0;
          }
        `}
      >
        <h3
          css={css`
            margin: 60px 0 20px;
            font-size: 32px;
            font-weight: 500;
          `}
        >
          收费价格
        </h3>
        <div
          css={css`
            margin: 20px 0 40px;
            font-size: 15px;
            color: #a2a2a2;
          `}
        >
          <p>提供多种付费策略</p>
          <div
            css={css`
              display: flex;
              width: 100%;
              .price-choice {
                flex: 1;
                margin: 0 2px;
              }
              .price-choice-active:hover {
                background: #f7f7f7;
                box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
                  0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                  0px 1px 10px 0px rgba(0, 0, 0, 0.12);
              }
              .price-item {
                padding: 25px 0;
                background: transparent;
                font-size: 13px;
                color: #5a5a5a;
                &:nth-of-type(2n) {
                  background: #f7f7f7;
                }
              }
              .price-item-l1 {
                height: 100px;
              }
              .price-item-buy {
                padding: 20px 0;
                height: 80px;
              }
            `}
          >
            <Paper elevation={0} className="price-choice">
              <div className="price-item price-item-l1"></div>
              <div className="price-item">订阅数量</div>
              <div className="price-item">订阅缓存</div>
              <div className="price-item">
                服务器
                <br />
                <br />
              </div>
              <div className="price-item">推送服务</div>
              <div className="price-item"></div>
            </Paper>
            <Paper elevation={0} className="price-choice price-choice-active">
              <div className="price-item price-item-l1">
                <h4
                  css={css`
                    color: rgba(238, 0, 0, 0.6);
                    font-size: 36px;
                    font-weight: 500;
                    line-height: 50px;
                  `}
                >
                  免费
                </h4>
              </div>
              <div className="price-item"> 10 个</div>
              <div className="price-item">仅缓存最近 3 个月数据</div>
              <div className="price-item">
                共用开发版服务器
                <br />
                全部免费用户共用 API 调用次数限制
              </div>
              <div className="price-item">不支持推送</div>
              <div className="price-item price-item-buy"></div>
            </Paper>
            <Paper elevation={0} className="price-choice price-choice-active">
              <div className="price-item price-item-l1">
                <h4
                  css={css`
                    color: rgba(238, 0, 0, 0.6);
                    font-size: 36px;
                    font-weight: 500;
                    line-height: 50px;
                  `}
                >
                  <span
                    css={css`
                      text-decoration: line-through;
                    `}
                  >
                    ￥??
                  </span>{" "}
                  <span
                    css={css`
                      font-size: 20px;
                    `}
                  >
                    / 年
                    <br />
                  </span>
                </h4>
              </div>
              <div className="price-item">无限制</div>
              <div className="price-item">缓存最近 1 年数据</div>
              <div className="price-item">
                专用生产服务器
                <br />
                不受 API 调用次数限制
              </div>
              <div className="price-item">3 个月内活跃用户</div>
              <div className="price-item price-item-buy">
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  href="/workboard"
                  css={css`
                    border-radius: 40px !important;
                  `}
                  disabled
                >
                  敬请期待
                </Button>
              </div>
            </Paper>
          </div>
        </div>
      </section>
      {/* <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
    </Layout>
  )
}

export default IndexPage
