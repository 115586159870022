import React from "react"
import { css } from "@emotion/core"

import iphoneStatusBar from "../../images/iphonex.jpg"

type IphoneProps = {
  screenshot: any
}

const IphoneProps = ({ screenshot }: IphoneProps) => {
  return (
    <div
      css={css`
        max-width: 300px;
        width: 30%;
        margin-bottom: 1.45rem;
        border: 10px solid #000;
        background: #343434;
        border-radius: calc(min(34px, 3vw));
        border-width: calc(min(8px, 1vw));
        box-shadow: 0 8px 13px rgba(0, 0, 0, 0.3);
        overflow: hidden;
        position: absolute;
        top: 8vw;
        right: 15px;

        &:after {
          content: "";
          width: 26%;
          height: 3px;
          background: #343434;
          display: block;
          left: 50%;
          transform: translate(-50%, -50%);
          bottom: 3px;
          position: absolute;
          border-radius: 6px;
        }
      `}
    >
      <img
        css={css`
          display: block;
          margin: 0;
        `}
        src={iphoneStatusBar}
      />
      <img
        css={css`
          display: block;
          overflow: hidden;
          margin: 0;
        `}
        src={screenshot}
      />
    </div>
  )
}

export default IphoneProps
